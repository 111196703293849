export default {
    'lang': 'Eng',
    'additional': 'Additional',
    'file': 'File',
    'course': 'Course',
    'purpose': 'Purpose',
    'language': 'Language',
    'all_course': 'All courses',
    'total_files': 'Total files',
    'file_manager': 'File manager',
    'uploaded_documents': 'Uploaded documents',
    'sign_out': 'Sign out',
    'fill_name': 'Fill in the name',
    'cover': 'Cover',
    'all_statuses': 'All statuses',
    'date_creation': 'Date of creation',
    'untitled': 'Untitled',
    'timetable_of_classes': 'Class schedule',
    'enable_scheduled_opening_of_classes': 'Enable scheduled opening of classes',
    'name': 'Name',
    'completed': 'Completed',
    'scheduled': 'Scheduled',
    'ended': 'Ended',
    'total': 'Total',
    'total_course': 'total courses',
    'add_stream': 'Add stream',
    'language_versions': 'Language versions',
    'issuance_of_certificate': 'Issuance of certificate',
    'select_certificate_issuance': 'Choose at least one condition to enable certificate issuance',
    'complete_number_of_lesson': 'Complete number (%) of lessons',
    'success_final_test': 'Pass final test successfully',
    'deleting_course': 'Course deletion',
    'delete_permanently': 'Delete permanently?',
    'all_permanently_deleted': 'All related documents will be deleted permanently',
    'publication': 'Publication',
    'lessons': 'lessons',
    'add_section': 'Add section',
    'name_section': 'Section name',
    'name_lesson': 'Lesson name',
    'deleting_lesson': 'Lesson deletion',
    'users': 'Users',
    'access_rights_management': 'Access rights management',
    'total_users': 'Total users',
    'name_user': 'Name',
    'was_online': 'Was online',
    'role': 'Role',
    'search_by_name_and_contact_details': 'Search by name and contact details',
    'add_access': 'Add access',
    'administrator': 'Administrator',
    'filler_el_mail': 'Fill in your e-mail',
    'filler_lastname': 'Fill in your surname',
    'filler_firstname': 'Fill in your name',
    'filler_middlename': 'Fill in your middle name',
    'filler_phone': 'Fill in your phone number',
    'email': 'E-mail',
    'lastname': 'Surname',
    'middlename': 'Middle name',
    'phone': 'Phone number',
    'create_user': 'Create user',
    'published': 'Published',
    'not_published': 'Not published ',
    'success': 'Successfully ',
    'published_s': 'Published',
    'not_published_s': 'Not published ',
    'course_name': 'Course name',
    'course_name_fill': 'Fill in the name',
    'course_card_description': 'Description on the course card',
    'course_description_fill': 'Fill in the description ',
    'course_page_description': 'Description on the course page',
    'img': 'Cover',
    'status_all': 'All statuses',
    'search_by_name': 'Search by name',
    'date_created': 'Date of creation',
    'save': 'Save',
    'no_name': 'No name',
    'flow_name': 'Flow name',
    'flow_number': 'Flow number',
    'start_date': 'Date of the start',
    'end_date': 'Date of the end',
    'lesson_schedule': 'Lesson schedule',
    'disable_flow_schedule': 'Enable scheduled opening of classes',
    'wrong_flow_dates': 'Wrong dates of the flow',
    'title_name': 'Title',
    'date': 'Date',
    'status': 'Status',
    'current': 'Current',
    'finished': 'Finished',
    'planned': 'Scheduled',
    'started': 'Started',
    'finished_p': 'Ended',
    'create_course': 'Create course',
    '{n} course': 'Total {n} course(s) ::: total {n} course(s) ::: total {n} course(s)',
    'description': 'Description ',
    'content': 'Content',
    'statistics': 'Statistics ',
    'students': 'Students ',
    'flows': 'Flows',
    'settings': 'Settings',
    'russian': 'Russian',
    'kazakh': 'Kazakh',
    'is_published': 'Publication',
    'certificate_pass': 'Certificate issuance',
    'certificate_pass_description': 'Choose at least one condition to enable certificate issuance',
    'finish_lesson_percent': 'Complete (%) number of lessons',
    'success_test_pass': 'Successfully pass final test',
    'complete_survey': 'Complete 100% of the lessons, pass the final test (if available), take the survey at the end of the course',
    'pass_cert_on_flow': 'Issue a certificate after course completion',
    'delete_course': 'Delete course',
    'delete_course_c': 'Delete a course',
    'delete_confirm': 'Delete permanently?',
    'delete_confirm_ext': 'All related documents will also be deleted permanently',
    'cancel': 'Cancel',
    'delete': 'Delete',
    'create_lesson': 'Create lesson',
    'add_module': 'Add module',
    'module_name': 'Module name',
    '{n} lesson': 'Total {n} lesson(s) ::: total {n} lesson(s) ::: total {n} lesson(s)',
    'task': 'Task',
    'lang_versions': 'Language versions ',
    'lesson_name': 'Lesson name',
    'delete_lesson': 'Lesson deletion',
    'delete_lesson_b': 'Delete lesson',
    'courses': 'Courses',
    'add_flow': 'Add flow',
    'link_to_telegram_chat_for_participants': 'Link to Telegram-chat for the participants ',
    'lesson_text': 'Lesson text',
    'load': 'Upload',
    'material': 'Material',
    'attach_material': 'Attach a material',
    'add': 'Add',
    'attached_materials': 'Attached materials',
    'select_type': 'Select type',
    'link': 'Link',
    'select_file': 'Select file',
    'file_loading': 'File loading...',
    'open': 'Open',
    'rename': 'Rename',
    'support': 'Support',
    'details': 'Details',
    'index_authorize': 'Please authorize to get access to the platform',
    'index_authorize_desc': 'Pass courses, participate in events, collect points, spend then in the item shop',
    'index_authorize_button': 'Log in',
    'add_media_to_question': 'Add media to the question ',
    'add_media': 'Add media',
    'delete_question': 'Delete question',
    'delete_media': 'Delete media',
    'final_test': 'Final test',
    'write_answer': 'Write an answer',
    'load_file': 'Upload a file',
    'add_answer': 'Add option',
    'answer_option': 'Answer option',
    'set_write_answer': 'Choose the right answer',
    'add_group': 'Add group',
    'group_sort': 'Sort by groups',
    'first_elem': 'First element',
    'second_elem': 'Second element',
    'edit': 'Edit',
    'tests': 'Test',
    'tests_description': 'Questions with answer options and other tasks with automatic checking ',
    'questioning': 'Surveying',
    'questioning_description': 'Open questions with manual checking ',
    'load_file_test': 'File loading',
    'load_file_test_description': 'Document uploading with manual checking ',
    'add_question': 'Add question',
    'dashboard': 'Dashboard ',
    'edit_user': 'Edit user',
    'upload_avatar': 'Upload avatar',
    'delete_user': 'Delete user',
    'password': 'Password',
    'password_confirmation': 'Confirm the password',
    'final_test_toggle': 'Enable final testing',
    'associate_pairs': 'Match pairs',
    'not_set': 'Not filled',
    'lesson_video': 'Lesson video',
    'file_locale': 'Media localization ',
    'file_locale_desc': 'Media is substituted for the current version',
    'delete_material': 'Delete material ',
    'mail': 'Mail',
    'authorize': 'Log in',
    'incorrect_login_or_password': 'Incorrect login or password',
    'flow': 'Flow',
    'apply': 'Apply',
    'person_has_been_trained': 'people completed education',
    'your_application_for_consideration': 'Your application is being reviewed',
    'your_application_has_been_rejected': 'Your application has been rejected ',
    'application_submitted': 'Application submitted ',
    'review_days': 'reviewing process can take up to 7 days',
    'telegram_chat_for_course_participants': 'telegram-chat for course participants',
    'open_question': 'Open question ',
    'option': 'Option ',
    'group': 'Group ',
    'group_name': 'Group name',
    'group_element': 'Group element',
    'task_description': 'Task description ',
    'notification': 'Notifications ',
    'certificate': 'Certificates',
    'achievement': 'Achievements ',
    'tour_slide_0_title': 'Welcome',
    'tour_slide_0_desc': 'Learn about basic platform opportunities ',
    'tour_slide_1_title': 'Start learning',
    'tour_slide_1_desc': 'Apply to one of the courses and get access to the lessons ',
    'tour_slide_2_title': 'Go forward',
    'tour_slide_2_desc': 'Acquire new knowledge, complete tasks and get points',
    'tour_slide_3_title': 'Get certificate',
    'tour_slide_3_desc': 'Complete education and get a certificate of completion',
    'skip': 'Skip',
    'next': 'Next',
    'complete': 'Complete',
    'points_per_question': 'points per question',
    'sent': 'Sent',
    'finish_lesson': 'Finish lesson',
    'attach_link': 'Attach link',
    'file_loaded': 'File uploaded',
    'load_presentation': 'Upload a presentation',
    'on_review': 'On review',
    'in_progress': 'In progress',
    'study_application': 'Study application ',
    'infinite': 'Infinite',
    'attempts_amount': 'Amount of attempts',
    'time_limit': 'Time limit',
    'no_limit': 'No limit',
    'pass_percent': 'Passing percentage (%)',
    'certificate_is_valid': 'Certificate is valid',
    'certificate_number': 'number of certificate',
    'student': 'Student',
    'date_of_issue': 'Date of issue',
    'download_certificate': 'Download certificate',
    'certificate_not_found': 'Certificate is not found',
    'total_students': 'total students',
    'completed_their_studies': 'completed their studies',
    'message': 'Message',
    'your_email': 'Your e-mail',
    'your_message': 'Your message',
    'creating_flow': 'Creating flow',
    'n from n': '{got} from {total}',
    'show_answers': 'Show answers ',
    'hide_answers': 'Hide answers ',
    'not_finished': 'Not finished',
    'failed': 'Failed',
    'passed': 'Passed',
    '{n} questions': 'Total <b>{n}</b> question ::: Total <b>{n}</b> questions ::: Total <b>{n}</b> questions',
    'set_answer': 'Answer',
    'start_test': 'Start test',
    'answer_later': 'Answer later',
    'go_to_website': 'Go to the website',
    'check_errors': 'Check errors',
    'restart': 'Restart',
    'get_correct {n}': 'get at least {n} correct answers in order to pass.',
    '{n} attempts': '<b>{n}</b> attempt ::: <b>{n}</b> attempts ::: <b>{n}</b> attempts',
    'test_text': 'You have {time} and {attempts}.',
    'test_text_time': 'You have {time} and <b>unlimited</b> attempts.',
    'test_text_attempts': 'You have<b>unlimited</b> time and {attempts}.',
    '{n} minutes': '<b>{n}</b> minute ::: <b>{n}</b> minutes ::: <b>{n}</b> minutes',
    'no limits': 'You have <b>unlimited</b> time and<b>unlimited</b> attempts.',
    'attempts': '{n} attempt ::: {n} attempts ::: {n} attempts',
    'continue': 'Continue',
    'error_change_course': "Can't change course during training time",
    'file_link': 'Link to the file',
    'lesson': 'Lesson',
    'finished_lesson': 'Finished lesson',
    'finished_tasks': 'Finished the task',
    'finished_course': 'completed the course',
    'started_course': 'started the course',
    'all_flows': 'All flows',
    'course_progress': 'Continue learning • {n}%',
    'open_profile': 'Open profile',
    'registration': 'Registration ',
    'date_of_registration': 'Date of registration ',
    '{n} students': 'Total {n} students ::: Total {n} students ::: Total {n} students',
    'asc': 'by ascending order',
    'desc': 'by descending order',
    'status_passed': 'Passed',
    'course_link': 'Link to course application form',
    'progress': 'Progress',
    'courses_completed': 'courses completed',
    'failed_auth': 'Failed',
    'add_student': 'Add student',
    'search': 'Search',
    'already_attached': 'The student is already enrolled in the course',
    'no_active_flow': 'No active flow',
    'main': 'Home',
    'course_no_link': 'No course link',
    'guide': 'Guideline',
    'file_load_tab': 'Loading',
    'open_questions': 'Open questions',
    'accept': 'Accept',
    'revision': 'Revision',
    'send_to_review': 'Submit a task for revision',
    'comment': 'Comment',
    'set_comment': 'Write a comment',
    'lesson_list': 'List of lessons',
    'open_date': 'Date of opening',
    'task_not_checked': 'Task is not checked',
    'not_passed': 'Not passed',
    'has_not_checked_questions': ' There are unchecked tasks',
    'reason': 'Reason',
    'decline': 'Decline',
    'not_accepted': 'Not accepted',
    'reception_closed': 'Reception closed',
    'no_attempts': 'No attempts of passing test',
    'not_finished_lesson': 'Not completed yet',
    'accepted': 'Accepted',
    'declined': 'Declined',
    'completed_the_lessons': 'are completed lesson',
    'started_the_lessons': 'are started lesson',
    'close': 'Close',
    'paste': 'Paste',
    'back': 'Back',
    'timer': 'Timer',
    'logout': 'Log out',
    'error': 'Error',
    'remove': 'Remove',
    'loading': 'Loading',
    'passed_status': 'Done',
    'on_revision': 'On revision',
    'tasks_results': 'Task results',
    'finished_at': 'Date of completion',
    'lesson_finished': 'Finished',
    'mark_answer': 'Rate the answer',
    'show_lesson_test_results': 'Show correct answers in lesson tests',
    'show_final_test_results': 'Show correct answers in the final test',
    'reviewed': 'Checked',
    'failed_course': 'Final test failed.',
    'failed_course_description': 'Unfortunately, you did not give enough correct answers. You can try again on the next flow.',
    'course_failed': 'Failed.',
    'test_fail_alert': 'If you fail the final test, you will be expelled.',
    'not_all_correct_answers_selected': 'Not all correct answers selected',
    'points': 'Points',
    'activity_log': 'Activity log',
    'action': 'Action',
    'user': 'User',
    'date_and_time': 'Date and time',
    'user_deleted': 'User is deleted',
    'user_created': 'User is created',
    'test_question_update': 'Test question is updated',
    'test_question_deleted': 'Test question is deleted',
    'test_question_created': 'Test question is created',
    'test_answer_deleted': 'Test answer is deleted',
    'test_answer_created': 'Test answer is created',
    'test_answer_update': 'Test answer is updated',
    'test_created': 'Test is created',
    'test_update': 'Test is updated',
    'task_question_update': 'Task question is updated',
    'task_question_created': 'Task question is created',
    'task_created': 'Task is created',
    'task_update': 'Task is updated',
    'material_update_kk': 'Material in Kazakh is updated',
    'material_update_ru': 'Material in Russian is updated',
    'material_created': 'Material is created',
    'material_deleted': 'Material is deleted',
    'lesson_update_ru': 'Lesson is updated',
    'lesson_update_kk': 'Lesson is updated',
    'lesson_template_update_ru': 'Course is updated',
    'lesson_template_update_kk': 'Course is updated',
    'lesson_template_update': 'Course is updated',
    'template_module_created': 'Module is created',
    'template_module_update': 'Module is updated',
    'template_module_deleted': 'Module is deleted',
    'lesson_template_deleted': 'Course is deleted',
    'lesson_template_created': 'Courae is created',
    'lesson_deleted': 'Lesson is deleted',
    'lesson_created': 'Lesson is created',
    'lesson_update': 'Lesson is updated',
    'flow_created': 'Flow is created',
    'flow_deleted': 'Flow is deleted',
    'flow_update': 'Flow is updated',
    'lesson_open_date_created': 'Lesson opening date is created',
    'lesson_open_date_updated': 'Lesson opening date is updated',
    'deleted': 'deleted',
    'admin_created': 'Admin is created',
    'student_created': 'Student is added',
    'viewed_notifications': 'Viewed notifications',
    'task_checked_lesson': 'Task is checked, lesson: "{name}" ',
    'task_checked_course': 'Task is ckecked, course: "{name}" ',
    'application_accepted': 'Application is accepted, course: "{name}" ',
    'students_passed': 'students passed',
    'avg_attempts': 'avg. number of attempts',
    'questions': 'Questions',
    'status_not_checked': 'Not checked',
    'result': 'Result',
    'attempts_singular': 'Attempts',
    'flow_ended': 'Flow is ended',
    'flow_ended_description': 'Unfortunately, the time is up. You can try again in the next flow!',
    'flow_ended_status': 'Overdue',
    'course_passed': 'Course is passed',
    'curator': 'Adviser',
    'no_permission': 'No permission',
    'edit_file': 'Edit file',
    'edit_link': 'Edit link',
    'test_title': 'Test title',
    'quiz_at_the_end_of_the_course': 'Quiz at the end of the course',
    'enable_end_of_course_poll': 'Enable the quiz at the end of the course',
    'interview': 'Interview',
    'select_answer': 'Select an answer',
    'raiting': 'Assess',
    'question_description': 'Question description',
    'multiple_choice': 'Multiple choice',
    'results': 'Results',
    'max_points': 'maximum points',
    'video_conference': 'Videoconference',
    'create': 'Create',
    'course_and_flow': 'Course and flow',
    'date_and_time_event': 'Event date and time',
    'join_link': 'Link to join',
    'enter_title': 'Enter the title',
    'choose_course': 'Choose a course',
    'total_conference': 'Total videoconferences',
    'date_event': 'Event date',
    'select_flow': 'Select flow',
    'active_students': 'active students',
    'online': 'online',
    'deadline_access': 'Enable access to the course',
    'end_date_access': 'Access end date',
    'deadline_access_closed': 'Once this date is reached, access to the course will automatically close.',
    'set_access': 'Enable access',
    'today': 'Today',
    'no': 'No',
    'send_notification': 'Send notification',
    'subject': 'Subject',
    'subject_description': 'Subject description',
    'text': 'Text',
    'text_description': 'Text description',
    'send': 'Send',
    'english': 'English',
    'file_not_selected': 'File not selected',
    'auto_check': 'Auto check',
    'manual_check': 'Manual check',
    'completion_skills': 'Completion skills',
    'skill_name': 'Skill name',
    'add_skill': 'Add skill',
    'skills': 'Skills',
    'show_more': 'Show more',
    'set_badge': 'Set badge',
    'no_achievements': 'No achievements',
    'reports': 'Reports',
    'reports_description': 'Requests to document generation',
    'report_all_types': 'All report types',
    'report_ready': 'Done',
    'queue': 'In queue',
    'students_report': 'Report on students',
    'refresh': 'Refresh',
    'download': 'Download',
    'create_date': 'By generated date({text})',
    'you_didnt_pass_test': 'You did not pass the test',
    'interview_results': 'Intervire results',
    'in_lesson': 'At the lesson',
    'download_files_without_questions': 'There are files to download without questions',
    'test_without_questions': 'There is a test without questions',
    'survey_without_questions': 'There is a survey without questions',
    'included_interview': 'The survey is included in the course with no questions',
    'final_testing_included': 'The final test is  included in the course with no questions',
    'problems_in_lesson': 'Some problems with the lesson',
    'problems_in_course': 'Some problems with the course',
    'attached_materials_s': 'Attached material',
    'next_lesson': 'Next lesson',
    'create_course_copy': 'Create course copy',
    'copy_course': 'Do you want to make a copy of the course?',
    'copy_course_descr': 'Copying the course will take time. Please wait.',
    'course_materials': 'Bonus materials',
    'course_client_materials': 'An exclusive bonus from the Astana Hub team',
    'prev_lesson': 'Previous lesson ',
    'finish_course': 'Finish course ',
    'finish_interview': 'Finish survey',
    'lesson_amount': 'Number of video lessons',
    'apply_to_course': 'Apply',
    'test_descr': 'Testing will help consolidate the theory and evaluate progress\n' +
        '\n' +
        'Do not be afraid to make mistakes, you have several attempts. Answer on {questions} from {total} to complete the course.\n' +
        '\n' +
        'Good luck! ',
    'test_success': 'Congratulations! You have completed the course!\n' +
        '\n' +
        'Complete the survey to receive your certificate.',
    'success_interview': 'Thanks for your feedback!',
    'certificate_': 'Certificate',
    "iin": "IIN",
    'archive': 'Archive',
    'restore_student': 'Restore student',
    'student_data': 'Student details',
    'restore_course': 'Restore course',
    'register': 'Sign up',
    'directions': 'Directions',
    'restore_password': 'Restore password',
    'restore': 'Restore',
    'restore_password_success': 'An email has been sent to your email to reset your password.',
    'no_new_notifications': 'No new notifications',
    'new_notifications': 'new notifications',
    'free_course_inv': 'Free course',
    'notif_text': 'A free course is available to you, click the button to get it!',
    'notif_button': 'Get',
    'notif_success': 'Your application has been successfully accepted! ✅',
    'notif_error': 'Sorry, but you have already used your free invitation. Keep up the good work to get a new free course! 👍',
    'notif_1': 'Congratulations on the successful completion of 2 courses! 🌟 We are pleased to give you free access to another exciting course. Congratulations and we wish you many exciting discoveries and success in your IT career! 🚀',
    'free_course': 'A free course was received',
    'telegram_bot_button': 'Telegram-бот',
    'courses_list': 'Courses catalog',
    'hello_name': "Hello {name}! ✌️",
    'course_search': 'Course search',
    'entrepreneurship': 'Entrepreneurship',
    'freelance': 'Freelance',
    'career': 'Career',
    'news': 'News',
    'create_news': 'Create news',
    '{n} news': 'Total {n} news ::: Total {n} news ::: Total {n} new’s',
    'hide_finished_lessons': 'Hide learned lessons',
    'started_first_lesson': 'Started the first lesson',
    'continue_video': 'Continue video from{time}',
    'section_1': 'Bring a friend and get a discount',
    'section_2': 'Take part in the promotion',
    'section_3': 'About the platform',
    'section_4': 'LMS platform - Astana Hub Education platform provides access to a variety of educational programs and courses in the field of information technology (IT) and technological entrepreneurship.',
    'section_5': 'Benefits of the platform',
    'section_6': 'Flexible learning schedule, the platform provides flexible learning opportunities',
    'section_7': 'Learning paths for quick entry into the IT field',
    'section_8': 'Learn whenever and wherever you want',
    'section_9': 'Learn from experts with huge experience',
    'section_10': 'Convenient platform',
    'section_11': 'Suitable for whom?',
    'section_12': 'Beginners',
    'section_13': 'For those who want to enter IT',
    'section_14': 'Experienced',
    'section_15': 'For those who want to enter IT',
    'section_16': 'Changing careers',
    'section_17': 'New paths to help you get into IT flexibly and quickly',
    'section_18': 'School and college students',
    'progress_course': '{n}% of material covered',
    'continue_study': 'Continue studying',
    'all': 'All',
    'task_comment': 'Comment',
    'contacts': 'Contacts',
    'not_authorized': 'Students added without authorization',
    'module': "Module",
    'courses_report': 'Course report',
    'section_19': '"Map of professions in the field of GameDev"',
    'section_20': 'Submit an application',
    'curator_access':"Adviser access",
    "my_courses": "My courses",
    "my_skills": "Skills",

};
