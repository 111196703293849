export default {
    'lang': 'Рус',
    'additional': 'Дополнительный',
    'file': 'Файл',
    'course': 'Курс',
    'purpose': 'Назначение',
    'language': 'Язык',
    'all_course': 'Все курсы',
    'all_language_versions': 'Все языковые версии',
    'total_files': 'Всего файлов',
    'file_manager': 'Менеджер файлов',
    'uploaded_documents': 'Загруженные документы',
    'sign_out': 'Выйти из аккаунта',
    'fill_name': 'Заполните название',
    'cover': 'Обложка',
    'all_statuses': 'Все статусы',
    'date_creation': 'Дата создания',
    'untitled': 'Без названия',
    'timetable_of_classes': 'Расписание занятий',
    'enable_scheduled_opening_of_classes': 'Включить открытие занятий по расписанию',
    'name': 'Название',
    'completed': 'Завершён',
    'scheduled': 'Запланирован',
    'ended': 'Завершили',
    'total': 'Всего',
    'total_course': 'курса',
    'add_stream': 'Добавить поток',
    'language_versions': 'Языковые версии',
    'issuance_of_certificate': 'Выдача сертификата',
    'select_certificate_issuance': 'Выберите хотя бы одно условие, чтобы включить выдачу сертификата',
    'complete_number_of_lesson': 'Завершить количество (%) уроков',
    'success_final_test': 'Успешно пройти итоговое тестирование',
    'deleting_course': 'Удаление курса',
    'delete_permanently': 'Удалить безвозвратно?',
    'all_permanently_deleted': 'Все связанные данные будут также удалены безвозвратно',
    'publication': 'Публикация',
    'lessons': 'уроков',
    'add_section': 'Добавить раздел',
    'name_section': 'Название раздела',
    'name_lesson': 'Название урока',
    'deleting_lesson': 'Удаление урока',
    'users': 'Пользователи',
    'access_rights_management': 'Управление правами доступа',
    'total_users': 'Всего пользователей',
    'name_user': 'Имя',
    'was_online': 'Был онлайн',
    'role': 'Роль',
    'search_by_name_and_contact_details': 'Поиск по имени и контактным данным',
    'add_access': 'Добавить доступ',
    'administrator': 'Администратор',
    'filler_el_mail': 'Заполните эл. почту',
    'filler_lastname': 'Заполните фамилию',
    'filler_firstname': 'Заполните имя',
    'filler_middlename': 'Заполните отчество',
    'filler_phone': 'Заполните номер телефона',
    'email': 'Электронная почта',
    'lastname': 'Фамилия',
    'middlename': 'Отчество',
    'phone': 'Номер телефона',
    'create_user': 'Создать пользователя',
    'published': 'Опубликовано',
    'not_published': 'Не опубликовано',
    'success': 'Успешно',
    'published_s': 'Опубликован',
    'not_published_s': 'Не опубликован',
    'course_name': 'Название курса',
    'course_name_fill': 'Заполните название',
    'course_card_description': 'Описание в карточке курса',
    'course_description_fill': 'Заполните описание',
    'course_page_description': 'Описание на странице курса',
    'img': 'Обложка',
    'status_all': 'Все статусы',
    'search_by_name': 'Поиск по названию',
    'date_created': 'Дата создания',
    'save': 'Сохранить',
    'no_name': 'Без названия',
    'flow_name': 'Название потока',
    'flow_number': 'Номер потока',
    'start_date': 'Дата начала',
    'end_date': 'Дата окончания',
    'lesson_schedule': 'Расписание занятий',
    'disable_flow_schedule': 'Включить открытие занятий по расписанию',
    'wrong_flow_dates': 'Неверные даты потока',
    'title_name': 'Название',
    'date': 'Дата',
    'status': 'Статус',
    'current': 'Текущий',
    'finished': 'Завершён',
    'planned': 'Запланирован',
    'started': 'Начали',
    'finished_p': 'Завершили',
    'create_course': 'Создать курс',
    '{n} course': 'Всего {n} курс ::: Всего {n} курса ::: Всего {n} курсов',
    'description': 'Описание',
    'content': 'Содержание',
    'statistics': 'Статистика',
    'students': 'Ученики',
    'flows': 'Потоки',
    'settings': 'Настройки',
    'russian': 'Русский',
    'kazakh': 'Қазақ',
    'is_published': 'Публикация',
    'certificate_pass': 'Выдача сертификата',
    'certificate_pass_description': 'Выберите хотя бы одно условие, чтобы включить выдачу сертификата',
    'finish_lesson_percent': 'Завершить количество (%) уроков',
    'success_test_pass': 'Успешно пройти итоговое тестирование',
    'complete_survey': 'Пройти 100% количество уроков, пройти итоговое тестирование (при его наличии), пройти опрос в конце курса',
    'pass_cert_on_flow': 'Выдавать сертификат после окончания потока',
    'delete_course': 'Удаление курса',
    'delete_course_c': 'Удалить курс',
    'delete_confirm': 'Удалить безвозвратно?',
    'delete_confirm_ext': 'Все связанные данные будут также удалены безвозвратно',
    'cancel': 'Отмена',
    'delete': 'Удалить',
    'create_lesson': 'Создать урок',
    'add_module': 'Добавить раздел',
    'module_name': 'Название раздела',
    '{n} lesson': 'Всего {n} урок ::: Всего {n} урока ::: Всего {n} уроков',
    'task': 'Задание',
    'lang_versions': 'Языковые версии',
    'lesson_name': 'Название урока',
    'delete_lesson': 'Удаление урока',
    'delete_lesson_b': 'Удалить урок',
    'courses': 'Курсы',
    'add_flow': 'Добавить поток',
    'link_to_telegram_chat_for_participants': 'Ссылка на Telegram-чат для участников',
    'lesson_text': 'Текст урока',
    'load': 'Загрузить',
    'material': 'Материал',
    'attach_material': 'Прикрепить материал',
    'add': 'Добавить',
    'attached_materials': 'Прикрепленные материалы',
    'select_type': 'Выберите тип',
    'link': 'Ссылка',
    'select_file': 'Выбрать файл',
    'file_loading': 'Файл загружается...',
    'open': 'Открыть',
    'rename': 'Переименовать',
    'support': 'Поддержка',
    'details': 'Подробнее',
    'index_authorize': 'Авторизуйтесь, чтобы получить полный доступ к платформе',
    'index_authorize_desc': 'Проходите курсы, участвуйте в событиях, зарабатывайте баллы,\n' + 'тратьте их в магазине товаров',
    'index_authorize_button': 'Войти',
    'add_media_to_question': 'Добавить медиа к вопросу',
    'add_media': 'Добавить медиа',
    'delete_question': 'Удалить вопрос',
    'delete_media': 'Удалить медиа',
    'final_test': 'Итоговое тестирование',
    'write_answer': 'Написать ответ',
    'load_file': 'Загрузить файл',
    'add_answer': 'Добавить вариант',
    'answer_option': 'Вариант ответа',
    'set_write_answer': 'Выбрать правильный ответ',
    'add_group': 'Добавить группу',
    'group_sort': 'Рассортировать по группам',
    'first_elem': 'Первый элемент',
    'second_elem': 'Второй элемент',
    'edit': 'Редактировать',
    'tests': 'Тестирование',
    'tests_description': 'Вопросы с вариантами ответа и другие задания с автоматической проверкой',
    'questioning': 'Анкетирование',
    'questioning_description': 'Открытые вопросы с ручной проверкой',
    'load_file_test': 'Загрузка файла',
    'load_file_test_description': 'Загрузка документа учениками с ручной проверкой',
    'add_question': 'Добавить вопрос',
    'dashboard': 'Показатели',
    'edit_user': 'Редактировать пользователя',
    'upload_avatar': 'Загрузить аватар',
    'delete_user': 'Удаление пользователя',
    'password': 'Пароль',
    'password_confirmation': 'Повторите пароль',
    'final_test_toggle': 'Включить итоговое тестирование',
    'associate_pairs': 'Сопоставить пары',
    'not_set': 'Не заполнено',
    'lesson_video': 'Видео урока',
    'file_locale': 'Локализация медиа',
    'file_locale_desc': 'Медиа заменено для текущей языковой версии',
    'delete_material': 'Удалить материал',
    'mail': 'Почта',
    'authorize': 'Войти',
    'incorrect_login_or_password': 'Неправильный логин или пароль',
    'flow': 'Поток',
    'apply': 'Подать заявку',
    'person_has_been_trained': 'человек прошло обучение',
    'your_application_for_consideration': 'Ваша заявка на рассмотрении',
    'your_application_has_been_rejected': 'Ваша заявка отклонена',
    'application_submitted': 'Заявка подана',
    'review_days': 'рассмотрение может занять до 7 рабочих дней',
    'telegram_chat_for_course_participants': 'Telegram-чат',
    'open_question': 'Открытый вопрос',
    'option': 'Вариант ',
    'group': 'Группа ',
    'group_name': 'Название группы',
    'group_element': 'Элемент группы',
    'task_description': 'Описание задания',
    'notification': 'Уведомления',
    'certificate': 'Сертификаты',
    'achievement': 'Достижения',
    'tour_slide_0_title': 'Добро пожаловать',
    'tour_slide_0_desc': 'Узнайте об основных возможностях платформы',
    'tour_slide_1_title': 'Начните обучение',
    'tour_slide_1_desc': 'Подайте заявку на один из курсов и получите доступ к урокам',
    'tour_slide_2_title': 'Двигайтесь вперёд',
    'tour_slide_2_desc': 'Получайте новые знания, выполняйте задания и получайте баллы',
    'tour_slide_3_title': 'Получите сертификат',
    'tour_slide_3_desc': 'Завершите обучение и получите сертификат о прохождении курса',
    'skip': 'Пропустить',
    'next': 'Далее',
    'complete': 'Завершить',
    'points_per_question': 'баллов за вопрос',
    'sent': 'Отправлено',
    'finish_lesson': 'Завершить урок',
    'attach_link': 'Прикрепить ссылку',
    'file_loaded': 'Файл загружен',
    'load_presentation': 'Загрузите презентацию',
    'on_review': 'На проверке',
    'in_progress': 'В процессе',
    'study_application': 'Заявка на обучение',
    'infinite': 'Бесконечное',
    'attempts_amount': 'Количество попыток',
    'time_limit': 'Ограничение времени',
    'no_limit': 'Без ограничения',
    'pass_percent': 'Порог прохождения (%)',
    'certificate_is_valid': 'Сертификат действителен',
    'certificate_number': 'сертификата',
    'student': 'Ученик',
    'date_of_issue': 'Дата выдачи',
    'download_certificate': 'Скачать сертификат',
    'certificate_not_found': 'Сертификат не найден',
    'total_students': 'всего учеников',
    'completed_their_studies': 'завершили обучение',
    'message': 'Сообщение',
    'your_email': 'Ваша эл. почта',
    'your_message': 'Ваше сообщение...',
    'creating_flow': 'Создание потока',
    'n from n': '{got} из {total}',
    'show_answers': 'Показать ответы',
    'hide_answers': 'Скрыть ответы',
    'not_finished': 'Не выполнено',
    'failed': 'Не пройдено',
    'passed': 'Пройдено',
    '{n} questions': 'Всего <b>{n}</b> вопрос ::: Всего <b>{n}</b> вопроса ::: Всего <b>{n}</b> вопросов',
    'set_answer': 'Ответить',
    'start_test': 'Начать тест',
    'answer_later': 'Ответить позже',
    'go_to_website': 'Перейти на сайт',
    'check_errors': 'Смотреть ошибки',
    'restart': 'Повторить',
    'get_correct {n}': 'Ответьте правильно хотя бы на {n} чтобы пройти.',
    '{n} attempts': '<b>{n}</b> попытка ::: <b>{n}</b> попытки ::: <b>{n}</b> попыток',
    'test_text': 'У вас {time} и {attempts}.',
    'test_text_time': 'У вас {time} и <b>бесконечное</b> число попыток.',
    'test_text_attempts': 'У вас <b>неограниченное</b> время и {attempts}.',
    '{n} minutes': '<b>{n}</b> минута ::: <b>{n}</b> минуты ::: <b>{n}</b> минут',
    'no limits': 'У вас <b>неограниченное</b> время и <b>бесконечное</b> число попыток.',
    'attempts': '{n} попытка ::: {n} попытки ::: {n} попыток',
    'continue': 'Продолжить',
    'error_change_course': 'Нельзя изменить курс во время обучения',
    'file_link': 'Ссылка на файл',
    'lesson': 'Урок',
    'finished_lesson': 'Посмотрели урок',
    'finished_tasks': 'Выполнили задание',
    'finished_course': 'завершили курс',
    'started_course': 'начали курс',
    'all_flows': 'Все потоки',
    'course_progress': 'Продолжить обучение • {n}%',
    'open_profile': 'Открыть профиль',
    'registration': 'Регистрация',
    'date_of_registration': 'Дата регистрации',
    '{n} students': 'Всего {n} ученик ::: Всего {n} ученика ::: Всего {n} учеников',
    'asc': 'по возростанию',
    'desc': 'по убыванию',
    'status_passed': 'Пройден',
    'course_link': 'Ссылка на форму подачи заявки',
    'progress': 'Прогресс',
    'courses_completed': 'курсов пройдено',
    'failed_auth': 'Неуспешно',
    'add_student': 'Добавить ученика',
    'search': 'Найти',
    'already_attached': 'Ученик уже зарегистрирован на курс',
    'no_active_flow': 'Нет активного потока',
    'main': 'Главная',
    'course_no_link': 'Нет формы заявки',
    'guide': 'Руководство',
    'file_load_tab': 'Загрузка',
    'open_questions': 'Открытые вопросы',
    'accept': 'Принять',
    'revision': 'На доработку',
    'send_to_review': 'Отправить задание на доработку',
    'comment': 'Комментарий',
    'set_comment': 'Напишите комментарий',
    'lesson_list': 'Список занятий',
    'open_date': 'Дата открытия',
    'task_not_checked': 'Не проверено задание',
    'not_passed': 'Не пройден',
    'has_not_checked_questions': 'Есть непроверенные задания',
    'reason': 'Причина',
    'decline': 'Отклонить',
    'not_accepted': 'Не пройдено',
    'reception_closed': 'Прием закрыт',
    'no_attempts': 'Нет попыток прохождения теста',
    'not_finished_lesson': 'Не завершен',
    'accepted': 'Принят',
    'declined': 'Отклонено',
    'completed_the_lessons': 'завершили урок',
    'started_the_lessons': 'начали урок',
    'close': 'Закрыть',
    'paste': 'Вставить',
    'back': 'Назад',
    'timer': 'Таймер',
    'logout': 'Выйти',
    'error': "Ошибка",
    'remove': 'Убрать',
    'loading': 'Загрузка',
    'passed_status': 'Выполнено',
    'on_revision': 'На доработке',
    'tasks_results': 'Результат задания',
    'finished_at': 'Дата завершения',
    'lesson_finished': 'Завершен',
    'mark_answer': 'Оцените ответ',
    'show_lesson_test_results': 'Показывать правильные ответы в тестах уроков',
    'show_final_test_results': 'Показывать правильные ответы в итоговом тесте',
    'reviewed': 'Проверено',
    'quiz_at_the_end_of_the_course': 'Опрос в конце курса',
    'enable_end_of_course_poll': 'Включить опрос в конце курса',
    'interview': 'Опрос',
    'select_answer': 'Выбрать ответ',
    'raiting': 'Оценить',
    'question_description': 'Описание вопроса',
    'multiple_choice': 'Выбор нескольких ответов',
    'questions': 'Вопросы',
    'results': 'Результаты',
    'max_points': 'баллов максимум',
    'failed_course': 'Итоговый тест провален',
    'failed_course_description': 'К сожалению, вы дали недостаточное количество правильных ответов. Вы можете попытаться снова на следующем потоке',
    'course_failed': 'Провален',
    'test_fail_alert': 'Если Вам не удастся успешно пройти итоговое тестирование, Вы будете исключены.',
    'not_all_correct_answers_selected': 'Выбраны не все правильные ответы',
    'video_conference': 'Видеоконференции',
    'create': 'Создать',
    'course_and_flow': 'Курс и поток',
    'date_and_time': 'Дата и время',
    'date_and_time_event': 'Дата и время проведения',
    'join_link': 'Ссылка на подключение',
    'enter_title': 'Укажите название',
    'choose_course': 'Выберите курс',
    'total_conference': 'Всего видеоконференций',
    'date_event': 'Дата проведения',
    'select_flow': 'Выбрать поток',
    'please_take_short_refresher_survey': 'Пожалуста, пройдите небольшой опрос о качестве курса',
    'certificate_will_become_available': 'Сертификат станет доступен сразу после завершения опроса',
    'take_survey': 'Пройти опрос',
    'send_notification': 'Отправить уведомление',
    'subject': 'Тема',
    'subject_description': 'Тема уведомления',
    'text': 'Текст',
    'text_description': 'Текст уведомления',
    'send': 'Отправить',
    'viewed_notifications': 'просмотренные уведомления',
    'task_checked_lesson': 'Задание проверено, урок: "{name}" ',
    'task_checked_course': 'Задание проверено, курс: "{name}" ',
    'application_accepted': 'Заявка одобрена, курс: "{name}" ',
    'points': 'Баллы',
    'activity_log': 'Лог активноcти',
    'action': 'Действие',
    'user': 'Пользователя',
    'user_deleted': 'Пользователь удалён',
    'user_created': 'Пользователь создан',
    'test_question_update': 'Вопрос теста обновлен',
    'test_question_deleted': 'Вопрос теста удалён',
    'test_question_created': 'Вопрос теста добавлен',
    'test_answer_deleted': 'Ответ теста удалён',
    'test_answer_created': 'Ответ теста добавлен',
    'test_answer_update': 'Ответ теста обновлён',
    'test_created': 'Тест создан',
    'test_update': 'Тест обновлён',
    'task_question_update': 'Вопрос задания обновлён',
    'task_question_created': 'Вопрос задания создан',
    'task_created': 'Задание создано',
    'task_update': 'Задание обновлено',
    'material_update_kk': 'Материал на казахском обновлён',
    'material_update_ru': 'Материал на русском обновлён',
    'material_created': 'Материал добавлен',
    'material_deleted': 'Материал удалён',
    'lesson_update_ru': 'Урок обновлён',
    'lesson_update_kk': 'Урок обновлён',
    'lesson_template_update_ru': 'Курс обновлён',
    'lesson_template_update_kk': 'Курс обновлён',
    'lesson_template_update': 'Курс обновлён',
    'template_module_created': 'Раздел создан',
    'template_module_update': 'Раздел обновлён',
    'template_module_deleted': 'Раздел удалён',
    'lesson_template_deleted': 'Курс удалён',
    'lesson_template_created': 'Курс создан',
    'lesson_deleted': 'Урок удалён',
    'lesson_created': 'Урок создан',
    'lesson_update': 'Урок обновлён',
    'flow_created': 'Поток создан',
    'flow_deleted': 'Поток удалён',
    'flow_update': 'Поток обновлён',
    'lesson_open_date_created': 'Дата открытия урока создана',
    'lesson_open_date_updated': 'Дата открытия урока обновлена',
    'deleted': 'удалён',
    'admin_created': 'Добавлен администратор',
    'student_created': 'Добавлен ученик',
    'students_passed': 'прошло человек',
    'avg_attempts': 'ср. число попыток',
    'status_not_checked': 'Не проверено',
    'result': 'Результат',
    'attempts_singular': 'Попытки',
    'flow_ended': 'Поток завершился',
    'flow_ended_description': 'К сожалению, вы не успели завершить курс. Вы можете попытаться снова на следующем потоке!',
    'flow_ended_status': 'Просрочено',
    'course_passed': 'Завершил обучение',
    'curator': 'Куратор',
    'no_permission': 'Нет доступа',
    'edit_file': 'Изменить файл',
    'edit_link': 'Изменить ссылку',
    'test_title': 'Тестирование',
    'active_students': 'активных учеников',
    'online': 'онлайн',
    'deadline_access': 'Включить доступ к курсу',
    'end_date_access': 'Дата завершения доступа',
    'deadline_access_closed': 'При наступлении этой даты доступ к курсу автоматически закроется',
    'set_access': 'Включить доступ',
    'today': 'Cегодня',
    'no': 'Нет',
    'english': 'Английский',
    'file_not_selected': 'Файл не выбран',
    'auto_check': 'Автоматическая проверка',
    'manual_check': 'Ручная проверка',
    'completion_skills': 'Навыки за прохождение',
    'skill_name': 'Название навыка',
    'add_skill': 'Добавить навык',
    'skills': 'Навыки',
    'show_more': 'Показать ещё',
    'set_badge': 'Выдавать бейдж',
    'no_achievements': 'нет достижений',
    'reports': 'Отчеты',
    'reports_description': 'Запросы на генерацию документов',
    'report_all_types': 'Все типы отчетов',
    'report_ready': 'Готово',
    'queue': 'В очереди',
    'students_report': 'Отчет по ученикам',
    'refresh': 'Обновить',
    'download': 'Скачать',
    'create_date': 'По дате генерации ({text})',
    'you_didnt_pass_test': 'Вы не прошли тест или задание',
    'interview_results': 'Результаты опроса',
    'in_lesson': 'В уроке',
    'download_files_without_questions': 'есть загрузка файлов без вопросов',
    'test_without_questions': 'есть тест без вопросов',
    'survey_without_questions': 'есть анкетирование без вопросов',
    'included_interview': 'В курсе включено опрос, но нет вопросов',
    'final_testing_included': 'В курсе включено итоговое тестирование, но нет вопросов',
    'problems_in_lesson': 'Проблемы в уроке',
    'problems_in_course': 'Проблемы в курсе',
    'attached_materials_s': 'Прикрепленный материал',
    'next_lesson': 'Следующий урок',
    'create_course_copy': 'Создать копию курса',
    'copy_course': 'Сделать копию курса?',
    'copy_course_descr': 'Копирование курса займет время. Пожалуйста, ожидайте. ',
    'course_materials': 'Бонусные материалы',
    'course_client_materials': 'Эксклюзивный бонус от команды Astana Hub',
    'prev_lesson': 'Предыдущий урок',
    'finish_course': 'Завершить курс',
    'finish_interview': 'Завершить опрос',
    'congrats': 'Поздравляем!',
    'tagline': 'Слоган',
    'lesson_amount': 'Количество видеоуроков',
    'apply_to_course': 'Записаться',
    'test_descr': 'Тестирование поможет закрепить теорию и оценить прогресс\n' +
        '\n' +
        'Не бойтесь ошибаться, у Вас несколько попыток. Ответьте на {questions} из {total} чтобы завершить курс.\n' +
        '\n' +
        'Удачи! ',
    'test_success': 'Поздравляем! Вы завершили курс!\n' +
        '\n' +
        'Пройдите опрос для получения сертификата.',
    'success_interview': 'Спасибо за обратную связь!',
    'certificate_': 'Сертификат',
    "iin": "ИИН",
    'archive': 'Архив',
    'restore_student': 'Восстановить студента',
    'student_data': 'Данные ученика',
    'restore_course': 'Восстановить курс',
    'register': 'Зарегистрироваться',
    'directions': 'Направления',
    'restore_password': 'Восстановить пароль',
    'restore': 'Восстановить',
    'restore_password_success': 'На вашу почту отправлено письмо для восстановления пароля.',
    'no_new_notifications': 'Нет новых уведомлений',
    'new_notifications': 'новые уведомления',
    'free_course_inv': 'Бесплатный курс',
    'notif_text': 'Вам доступен бесплатный курс, нажмите на кнопку чтобы получить!',
    'notif_button': 'Получить',
    'notif_success': 'Ваша заявка успешно принята! ✅',
    'notif_error': 'Извините, но вы уже использовали бесплатное приглашение. Продолжайте в том же духе чтобы получить новый бесплатный курс! 👍',
    'notif_1': 'Поздравляем вас с успешным завершением 2 курсов! 🌟 Мы рады подарить вам бесплатный доступ к ещё одному увлекательному курсу. Поздравляем и желаем вам множества увлекательных открытий и успехов в вашей IT-карьере! 🚀',
    'free_course': 'Получен бесплатный курс',
    'telegram_bot_button': 'Telegram-бот',
    'courses_list': "Каталог курсов",
    'hello_name': "Привет {name}! ✌",
    'course_search': 'Поиск Курсов',
    'entrepreneurship': 'Предпринимательство',
    'freelance': 'Фриланс',
    'career': 'Карьера',
    'news': 'Новости',
    'create_news': 'Создать новость',
    '{n} news': 'Всего {n} новость ::: Всего {n} новости ::: Всего {n} новостей',
    'hide_finished_lessons': 'Скрыть пройденные уроки',
    'started_first_lesson': 'Начали первый урок',
    'continue_video': 'Продолжить видео с {time}',
    'section_1': 'Приведи друга получи скидку',
    'section_2': 'Прими участие в акции',
    'section_3': 'О платформе',
    'section_4': 'LMS платформа - Образовательная платформа Астана Хаб предоставляет доступ к разнообразным образовательным программам и курсам в области информационных технологий (IT) и технологического предпринимательства.',
    'section_5': 'Преимущества платформы',
    'section_6': 'Гибкий график обучения, платформа предоставляет гибкие возможности обучения',
    'section_7': 'Направления обучения для быстрого входа в IT сферу ',
    'section_8': 'Обучайся когда и где угодно ',
    'section_9': 'Учись от экспертов с большим опытом',
    'section_10': 'Удобная платформа',
    'section_11': 'Кому подходит?',
    'section_12': 'Начинающим',
    'section_13': 'Для тех кто хочет войти в IT',
    'section_14': 'Опытным',
    'section_15': 'Для тех кто хочет войти в IT',
    'section_16': 'Меняющим свою сферу деятельности',
    'section_17': 'Новые направления помогающие гибко и быстро влиться в IT',
    'section_18': 'Школьникам и студентам',
    'progress_course': '{n}% материала пройдено',
    'continue_study': 'Продолжить обучение',
    'all': 'Все',
    'task_comment': 'Комментарий',
    'contacts': 'Контакты',
    'not_authorized': 'Учеников добавлено без авторизации',
    'module': "Модуль",
    'courses_report': 'Отчет по курсам',
    'section_19': 'Курс "Карта профессий в сфере GameDev"',
    'section_20': 'Подать заявку',
    'login_with_astanahub': 'Войти через astanahub',
    'curator_access': "Кураторский доступ",
    "my_courses": "Мои курсы",
    "my_skills": "Навыки",
};
